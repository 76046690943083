import React, { useState, useEffect, useContext } from 'react';

import Email from '@assets/contact/email';
import LanguageContext from '../../context/language-context';

export default function ContactComponent() {
  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      contactUsViaEmail: 'Contact us via email at',
      weAreSocial: 'We are social',
    },
    esp: {
      contactUsViaEmail: 'Contáctenos por correo electrónico a',
      weAreSocial: 'Somos sociales',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  return (
    <div className="contact-component-container">
      <div className="contact-info-email">
        <h4>{currentContent?.contactUsViaEmail}</h4>
        <div className="contact-info-email-id">
          <Email />
          <a href="mailto:hhes@hhesbooks.com">
            <h6> hhes@hhesbooks.com</h6>
          </a>
        </div>
      </div>
      <div className="contact-info-socialmedia">
        <h4>{currentContent?.weAreSocial}</h4>
        <div className="contact-info-sociallinks">
          <a href="https://www.facebook.com/">
            <span className="contact-sociallinks ">Facebook</span>
          </a>

          <a href="https://www.linkedin.com/">
            <span className="contact-sociallinks">Linkedin</span>
          </a>
          <a href="https://www.twitter.com/">
            <span className="contact-sociallinks">Twitter</span>
          </a>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';

import ContactComponent from '@components/contact-component/contact-component';
import Layout from '@layouts';
import Seo from '@layouts/common/seo';
import LeftArrowContact from '@assets/contact/left-arrow';

const PrivacyPolicyPage = () => {
  const data = useStaticQuery(graphql`
    query PrivacyPolicy {
      prismicPrivacyPolicy {
        data {
          privacy_policy {
            raw
          }
        }
      }
    }
  `);

  const { raw } = data.prismicPrivacyPolicy?.data.privacy_policy;

  return (
    <Layout>
      <Seo title="privacy policy" />
      <div className="privacy-policy-container u-margin-top-lg common-container u-margin-bottom-md">
        <ul class="privacy-policy-breadcrumb">
          <Link to="/">
            <li className="opacity-low">Home</li>
          </Link>
          <LeftArrowContact />
          <li>Privacy Policy</li>
        </ul>

        <div className="privacy-policy-body-holder">
          <div className="privacy-policy-body">
            <h1>Privacy Policy</h1>

            <div className="privacy-policy-text">{RichText.render(raw)}</div>
          </div>

          <ContactComponent />
        </div>
        <div className="privacy-policy-background-container"></div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
